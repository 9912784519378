import { useAuth } from 'common/auth/auth-provider'
import ErrorBoundary from 'common/error-boundary'
import Backdrop from 'components/ui/backdrop'
import { Suspense, lazy } from 'react'
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import AnonymousRoute from './anonymous-route'
import BackofficeRoute from './backoffice-route'
import PrivateRoute from './private-route'
import { getRedirectRoute } from './redirect'
//import AccountRoute from './account-route'

const AdminLayout = lazy(() => import('layouts/admin'))

const Login = lazy(() => import('pages/login'))

const Dashboard = lazy(() => import('pages/admin/dashboard'))

const EmployeeDetail = lazy(() => import('pages/admin/employees/detail'))
const EmployeesList = lazy(() => import('pages/admin/employees/list'))

const UserDetail = lazy(() => import('pages/admin/users/detail'))
const UsersList = lazy(() => import('pages/admin/users/list'))

const ContractDetail = lazy(() => import('pages/admin/contracts/detail'))
const ContractsList = lazy(() => import('pages/admin/contracts/list'))
const ContractPDF = lazy(() => import('pages/admin/contracts/pdf'))

const ProposalDetail = lazy(() => import('pages/admin/proposals/detail'))
const ProposalsList = lazy(() => import('pages/admin/proposals/list'))

const PropertyDetail = lazy(() => import('pages/admin/properties/detail'))

const PropertiesList = lazy(() => import('pages/admin/properties/list'))
const CondominiumList = lazy(() => import('pages/admin/condominium/list'))
const CondominiumCreate = lazy(() => import('pages/admin/condominium/create'))
const CondominiumSee = lazy(() => import('pages/admin/condominium/see'))
const PropertiesSummary = lazy(() => import('pages/admin/properties/summary'))

const MyAccount = lazy(() => import('pages/admin/my-account/detail'))

const Agency = lazy(() => import('pages/admin/agency'))

const SiteLayout = lazy(() => import('layouts/site'))

const Home = lazy(() => import('pages/site/home'))
const PropertyClientDetail = lazy(() => import('pages/site/property-detail'))
const Favorite = lazy(() => import('pages/site/favorites'))
const Announce = lazy(() => import('pages/site/announce'))
const KnowUs = lazy(() => import('pages/site/know-us'))
const Search = lazy(() => import('pages/site/search'))

export default function Router() {
  const auth = useAuth()
  const isAuthenticated = auth.isAuthenticated()
  const redirectRoute = getRedirectRoute(isAuthenticated)

  return (
    <ErrorBoundary>
      <Suspense fallback={<Backdrop />}>
        <BrowserRouter>
          <Routes>
            {/*Admin Pages*/}
            <Route
              path={'/admin'}
              element={
                <PrivateRoute
                  isAuthenticated={isAuthenticated}
                  redirectTo="/login"
                >
                  <AdminLayout />
                </PrivateRoute>
              }
            >
              <Route
                index
                element={auth.isEmployee ? <Dashboard /> : <MyAccount />}
              />
              <Route path="properties">
                <Route path="" element={<PropertiesList />} />
                <Route path=":id" element={<PropertyDetail />} />
                <Route path="summary/:id" element={<PropertiesSummary />} />
              </Route>
              <Route path="condominium">
                <Route path="" element={<CondominiumList />} />
                <Route path=":id" element={<CondominiumCreate />} />
                <Route path="see/:id" element={<CondominiumSee />} />
              </Route>

              {auth.isEmployee ? (
                <>
                  <Route path="proposals">
                    <Route path="" element={<ProposalsList />} />
                    <Route path=":id" element={<ProposalDetail />} />
                  </Route>
                  <Route path="contracts">
                    <Route path="" element={<ContractsList />} />
                    <Route path=":id" element={<ContractDetail />} />
                  </Route>
                  <Route path="users">
                    <Route path="" element={<UsersList />} />
                    <Route path=":id" element={<UserDetail />} />
                  </Route>
                  <Route
                    path="employees"
                    element={
                      <PrivateRoute
                        isAuthenticated={auth.isAdmin}
                        redirectTo="/admin"
                      >
                        <Outlet />
                      </PrivateRoute>
                    }
                  >
                    <Route path="" element={<EmployeesList />} />
                    <Route path=":id" element={<EmployeeDetail />} />
                  </Route>
                  <Route path="agency">
                    <Route path="" element={<Agency />} />
                  </Route>
                </>
              ) : null}
            </Route>
            {/*Contract Page*/}
            <Route
              path="/admin/contracts/:id/pdf"
              element={
                <PrivateRoute
                  isAuthenticated={isAuthenticated}
                  redirectTo="/login"
                >
                  <BackofficeRoute>
                    <ContractPDF />
                  </BackofficeRoute>
                </PrivateRoute>
              }
            />
            {/*Site Pages*/}
            <Route path="/" element={<SiteLayout />}>
              <Route index element={<Home />} />
              <Route path="favorites" element={<Favorite />} />
              <Route path="property/:id" element={<PropertyClientDetail />} />
              <Route path="announce" element={<Announce />} />
              <Route path="know-us" element={<KnowUs />} />
            </Route>

            <Route path="/" element={<SiteLayout hideFooter={true} hideHeader={true}/>}>
              <Route
                path="login"
                element={
                  <AnonymousRoute
                    isAuthenticated={isAuthenticated}
                    redirectTo={redirectRoute}
                  >
                    <Login />
                  </AnonymousRoute>
                }
              />
            </Route>

            <Route path="/explore" element={<SiteLayout hideFooter={true} />}>
              <Route index element={<Search />} />
            </Route>

            <Route
              path="*"
              element={<Navigate to={isAuthenticated ? '/admin' : '/'} />}
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </ErrorBoundary>
  )
}
